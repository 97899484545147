

function Right() {
    return (
        <div className="TwoSide" id="Right">
            <p>This is the personal website of <b>Leland Thomas Rogers</b>.</p>
        </div>
    );
}

export default Right;

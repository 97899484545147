import './App.css';
import Left from './components/Left.js'
import Right from './components/Right.js'

function App() {
  return (
    <div className="App">
      <Left />
      <Right />
    </div>
  );
}

export default App;

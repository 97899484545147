
function Left() {
    return (
        <div className="TwoSide" id="Left">
            <h1>ltrii</h1>
        </div>
    );
}

export default Left;
